import React from "react";
import './Footer.css';


// Here we are using object destructuring assignment to pluck off our variables from the props object
// We assign them to their own variable names
const Footer = () => {
  const linkedinUrl = process.env.REACT_APP_LINKEDIN_PROFILE_URL;
  const mediumUrl = process.env.REACT_APP_MEDIUM_PROFILE_URL;
  const twitterUrl = process.env.REACT_APP_TWITTER_PROFILE_URL;
  const instagramUrl = process.env.REACT_APP_INSTAGRAM_PROFILE_URL;
  const githubUrl = process.env.REACT_APP_GITHUB_PROFILE_URL;

  return (
    <footer>
      <div className="footer-container px-5 container-fluid fixed-bottom">
      <ul className="nav social-links d-flex col-12 justify-content-center">
        <li className="social-item">
          <a href={linkedinUrl} target="_blank" rel="noreferrer"><i className="fa-brands fa-linkedin fa-xl px-3"></i></a>
        </li>
        <li className="social-item">
          <a href={githubUrl} target="_blank" rel="noreferrer"><i className="fa-brands fa-github fa-xl px-3"></i></a>
        </li>
        <li className="social-item">
          <a href={mediumUrl} target= "_blank" rel="noreferrer"><i className="fa-brands fa-medium fa-xl px-3"></i></a>
        </li>
        <li className="social-item">
          <a href={twitterUrl} target= "_blank" rel="noreferrer" ><i className="fa-brands fa-twitter fa-xl px-3"></i></a>
        </li>
        <li className="social-item">
          <a href={instagramUrl} target= "_blank" rel="noreferrer" ><i className="fa-brands fa-instagram fa-xl px-3"></i></a>
        </li>
      </ul>
      <p className="copywrite-info">{}</p>
      </div>
    </footer>
  );
}

export default Footer;