import React from 'react';
import "./Blog.css";

export default function Blog() {
  return (
    <body>
      <h1 className='section-header'><u>Blog Page Coming Soon!</u></h1>
      {/* <p>

      </p> */}
    </body>
  );
}
